.hardMode-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.hardMode-toggle h3 {
  font-family: "Cairo", sans-serif;
  margin-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: red;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #28d478;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.hardModeIcon {
  margin-right: 10px;
}

@media screen and (min-width: 768px) {
  #hardModeInfo {
    max-width: 200px;
  }
}
@media screen and (max-width: 768px) {
  #hardModeInfo {
    max-width: 160px;
  }
}
