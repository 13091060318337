.how-to-h2 {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

.how-to-h3 {
  font-family: "Cairo", sans-serif;
}

.how-to-ul {
  font-family: "Cairo", sans-serif;
}

.how-to-ul li {
  margin: 5px 0;
}
.how-to-ul .span-yellow {
  background-color: #ffd000;
  padding: 0 1px;
}

.how-to-ul .span-green {
  background-color: #28d478;
  padding: 0 1px;
}


@media screen and (max-width: 768px){
  .how-to-ul li {
    font-size: 12px;
  }
}

.twitter-link{
  color: #1DA1F2;
}