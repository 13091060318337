@media screen and (max-width: 349px) {
  .search-bar {
    font-size: 16px;
    padding: 20px;
    height: 60.5px;
  }

  .search-button {
    width: 20%;
    font-size: 16px;
    height: 60.5px;
  }

  .search-bar-container {
    max-width: 400px;
    width: 100%;
  }
  .suggestions-dropdown {
    width: 224px;
  }
}

@media screen and (min-width: 350px) and (max-width: 387px) {
  .search-bar {
    font-size: 16px;
    padding: 20px;
    height: 60.5px;
  }

  .search-button {
    width: 20%;
    font-size: 16px;
    height: 60.5px;
  }

  .search-bar-container {
    max-width: 400px;
    width: 100%;
  }
  .suggestions-dropdown {
    width: 268px;
  }
}

@media screen and (min-width: 388px) and (max-width: 7294px) {
  .search-bar {
    font-size: 16px;
    padding: 20px;
    height: 60.5px;
  }

  .search-button {
    width: 20%;
    font-size: 16px;
    height: 60.5px;
  }

  .search-bar-container {
    max-width: 400px;
    width: 100%;
  }
  .suggestions-dropdown {
    width: 280px;
  }
}

@media screen and (min-width: 395px) and (max-width: 768px) {
  .search-bar {
    font-size: 16px;
    padding: 20px;
    height: 60.5px;
  }

  .search-button {
    width: 20%;
    font-size: 16px;
    height: 60.5px;
  }

  .search-bar-container {
    max-width: 400px;
    width: 100%;
  }
  .suggestions-dropdown {
    width: 288px;
  }
}

@media screen and (min-width: 768px) {
  .search-bar {
    font-size: 18px;
    padding: 30px;
    height: 83px;
  }

  .search-button {
    width: 20%;
    font-size: 18px;
    height: 83px;
  }

  .suggestions-dropdown {
    width: 432px;
  }
  .search-bar-container {
    max-width: 580px;
  }
}

.red-border{
  border: 1px solid red !important;
}
.search-bar {
  width: 100%;
  box-sizing: border-box;
  font-weight: 700;
  border: 1px solid black;
  color: black;
  position: relative;
  background-color: white;
  font-family: 'Cairo', sans-serif;
  border-radius: 0;
}

.search-bar:focus {
  border: 1px solid #b1c2d2;
  outline: none !important;
}

.search-button {
  box-sizing: border-box;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
  font-weight: 700;
  border: 1px solid black;
  border-left: none;
  font-family: 'Cairo', sans-serif;
}

.search-button:hover {
  background-color: transparent;
}

.search-bar-container {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
  padding: 0 20px;
  box-sizing: border-box;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.div-container {
  width: 80%;
}

.suggestions-dropdown {
  position: absolute;
  z-index: 4;
  background-color: white;
}

.suggestions-dropdown ul {
  padding-left: 0;
  margin: 0;
  border: 1px solid #b1c2d2;
  border-top: none;
}

.suggestions-dropdown li {
  list-style-type: none;
  padding-left: 0;
  cursor: pointer;
  font-family: 'Cairo', sans-serif;
  color: black;
}

.suggestion {
  width: 100%;
  padding: 10px 0;
}

.suggestion:hover {
  background-color: #b1c2d2;
}