.table-body .table-row {
  display: grid;
  border-top: 1px solid black;
  animation: fadeInAnimation 1s ease-in;
}

.table-container {
  margin-left: auto;
  margin-right: auto;
  font-family: "Cairo", sans-serif;
}

.table-head .table-row {
  display: grid;
  font-size: 0.8em;
  text-transform: uppercase;
  color: black;
  font-weight: 900;
  text-align: center;
  font-family: "Cairo", sans-serif;
}

.table-body .table-row .table-cell:first-child {
  grid-area: name;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 700;
}

.table-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px 1px;
  margin-bottom: none;
  margin-left: none;
  position: relative;
}

.table-row .table-cell {
  font-weight: 500;
}
.table-cell:nth-child(2) {
  grid-area: team;
  flex-direction: column;
}

.table-cell:nth-child(3) {
  grid-area: conf;
}

.table-cell:nth-child(4) {
  grid-area: div;
}

.table-cell:nth-child(5) {
  grid-area: pos;
}

.table-cell:nth-child(6) {
  grid-area: sht;
}

.table-cell:nth-child(7) {
  grid-area: age;
}

.table-cell:nth-child(8) {
  grid-area: ctry;
  flex-direction: column;
}

.table-cell:nth-child(9) {
  grid-area: num;
}

.table-body .table-row:first-child {
  border-top: none;
}

.not-safari {
  position: relative;
}

.playoffs .table-cell:nth-child(3) {
  grid-area: pos;
}

.playoffs .table-cell:nth-child(4) {
  grid-area: sht;
}

.playoffs .table-cell:nth-child(5) {
  grid-area: age;
}

.playoffs .table-cell:nth-child(6) {
  grid-area: ctry;
  flex-direction: column;
}

.playoffs .table-cell:nth-child(7) {
  grid-area: num;
}


@media screen and (max-width: 450px) {
  .table-body .table-row {
    grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "name name name name name name name name"
      "team conf div pos sht age ctry num";
    padding: 0 20px;
  }

  .table-body .table-row.playoffs {
    grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "name name name name name name"
      "team pos sht age ctry num";
    padding: 0 20px;
  }

  .table-body .table-cell {
    font-size: 12px;
    font-weight: 600;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 30%);
    margin: 5px 1px;
  }

  .table-container {
    max-width: 540px;
    padding-top: 40px;
    padding: 10px 20px;
  }

  .table-body {
    max-height: calc(100vh - 300px);
    min-height: 140px;
    overflow-x: visible;
    overflow-y: scroll;
    margin: 0 -20px;
  }

  .table-body-unlimited{
    max-height: calc(100vh - 380px);
  }


  .table-head .table-row {
    grid-template-areas: "name team conf div pos sht age ctry num";
    grid-template-columns: 0 50px 60px 50px 1fr 0.8fr 0.8fr 1.5fr 1fr;
  }

  .table-head .table-row.playoffs {
    grid-template-areas: "name team pos sht age ctry num";
    grid-template-columns: 0 1.2fr 1fr 1fr 1fr 1fr 1fr;
  }

  .table-img {
    height: 40px;
    width: 40px;
  }

  .table-head {
    margin: 0 -20px;
    padding: 0 20px;
    border-bottom: 2px solid black;
    overflow-x: hidden;
    position: relative;
  }

  .table-body .table-row .table-cell:first-child > div {
    padding: 16px 0;
  }

  .table-body .table-row .table-cell > div {
    padding: 14px 8px;
  }

  .table-body .table-row .table-cell:first-child {
    margin: 5px 0;
    padding: 0 5px;
  }
  .conference-logo {
    height: 30px;
    width: 40px;
  }
  .division-logo {
    height: 30px;
    width: 40px;
  }
  .ANA {
    height: 30px;
  }

  .OTT {
    height: 35px;
  }
  .SEA {
    width: 30px;
  }
}
@media screen and (min-width: 451px) and (max-width: 768px) {
  .table-body .table-row {
    grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "name name name name name name name name"
      "team conf div pos sht age ctry num";
    padding: 0 20px;
  }

  .table-body .table-row.playoffs {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "name name name name name name"
      "team pos sht age ctry num";
    padding: 0 20px;
  }

  .table-body .table-cell {
    font-size: 12px;
    font-weight: 600;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 30%);
    margin: 5px 1px;
  }

  .table-container {
    max-width: 540px;
    padding-top: 40px;
    padding: 40px 20px;
  }

  .table-body {
    max-height: calc(100vh - 300px);
    min-height: 140px;
    overflow-x: visible;
    overflow-y: scroll;
    margin: 0 -20px;
  }

  .table-head .table-row {
    grid-template-areas: "name team conf div pos sht age ctry num";
    grid-template-columns: 0 45px 1fr 0.8fr 1fr 0.6fr 1fr 1fr 1fr;
  }

  .table-head .table-row.playoffs {
    grid-template-areas: "name team pos sht age ctry num";
    grid-template-columns: 0 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .table-img {
    height: 40px;
    width: 40px;
  }

  .table-head {
    margin: 0 -20px;
    padding: 0 20px;
    border-bottom: 2px solid black;
    overflow-x: hidden;
    position: relative;
  }

  .table-body .table-row .table-cell:first-child > div {
    padding: 16px 0;
  }

  .table-body .table-row .table-cell > div {
    padding: 14px 8px;
  }

  .table-body .table-row .table-cell:first-child {
    margin: 5px 0;
    padding: 0 5px;
  }
  .conference-logo {
    height: 30px;
    width: 40px;
  }
  .division-logo {
    height: 30px;
    width: 40px;
  }
  .ANA {
    height: 30px;
  }
  .OTT {
    height: 35px;
  }
  .SEA {
    width: 30px;
  }
}

@media screen and (min-width: 768px) {
  .table-body .table-row {
    grid-template-columns: 250px 100px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "name team conf div pos sht age ctry num";
    box-sizing: border-box;
  }

  .table-body .table-row.playoffs {
    grid-template-columns: 250px 150px 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "name team pos sht age ctry num";
    box-sizing: border-box;
  }
  

  .table-head .table-row {
    grid-template-columns: 250px 100px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "name team conf div pos sht age ctry num";
    box-sizing: border-box;
    border-bottom: 3px solid black;
  }

  .table-head .table-row.playoffs {
    grid-template-columns: 250px 150px 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "name team pos sht age ctry num";
    box-sizing: border-box;
    border-bottom: 3px solid black;
  }

  .table-container {
    max-width: 1024px;
    padding: 20px 20px;
  }
  .table-body .table-cell {
    font-size: 16px;
    box-sizing: border-box;
    font-weight: 600;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 30%);
  }

  .table-row .table-cell:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }
  .table-grid {
    overflow: hidden;
  }
  .table-img {
    height: 50px;
    width: 50px;
  }

  .table-body {
    max-height: calc(100vh - 470px);
    min-height: 140px;
    overflow-x: visible;
    overflow-y: scroll;
    box-sizing: border-box;
  }

  .table-body-unlimited {
    max-height: calc(100vh - 550px);
  }

  .table-body .table-row .table-cell:first-child > div {
    padding: 16px 0;
  }

  .table-body .table-row .table-cell > div {
    padding: 14px 8px;
  }
  .conference-logo {
    height: 40px;
    width: 50px;
  }
  .division-logo {
    height: 40px;
    width: 50px;
  }
  .OTT {
    height: 45px;
  }
  .ANA {
    height: 40px;
  }
  .SEA {
    width: 40px;
  }
}

@keyframes fadeInAnimation {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
