.main-header {
  box-sizing: border-box;
  /* background-color: white; */
}

.head-link {
  font-size: 28px;
  font-family: "Oswald", sans-serif;
}

@media screen and (max-width: 768px) {
  .header-mobile {
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: center;
  }
  .header-img {
    /* margin-top: 10px; */
    height: 80px;
    margin-left: auto;
    margin-right: auto;
  }
  .header {
    display: none;
  }
  .main-header {
    height: 82px;
    margin-bottom: 50px;
    border-bottom: 2px solid black;
  }
  .mobile-right {
    position: absolute;
    right: 45px;
    margin-top: -10px;
  }
  .mobile-left-two {
    position: absolute;
    left: 80px;
    margin-top: -10px;
  }
  .mobile-left {
    position: absolute;
    left: 45px;
    margin-top: -10px;
  }

  .mobile-left-one {
    position: absolute;
    left: 10px;
    margin-top: -10px;
  }

  .mobile-right-one {
    position: absolute;
    right: 10px;
    margin-top: -10px;
  }
}

@media screen and (min-width: 768px) {
  .header-mobile {
    display: none;
  }
  .header-img {
    height: 160px;
  }
  .mobile {
    display: none;
  }
  .main-header {
    border-bottom: none;
    text-align: center;
  }
  .link-container {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid black;
  }
  .link-container .head-link {
    color: black;
    margin: 0px 20px;
    cursor: pointer;
  }
  .link-container .head-link:hover {
    text-decoration: underline;
  }
}

.MuiTab-wrapper{
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  font-weight: 600;
}


.MuiTab-textColorInherit {
  opacity: 1 !important;
}

.MuiTab-textColorInherit.Mui-selected .MuiTab-wrapper{
  opacity: 1;
}

.MuiTab-textColorInherit .MuiTab-wrapper{
  opacity: 0.5;
}

.activeMode{
  border: none;
  border-bottom: 2px solid red;
}

.inactiveMode{
  border: none;
  opacity: 0.5;
}

.mode-button{
  background: none;
  cursor: pointer;
  transition: 0.3s all;
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  font-weight: 600;
  padding: 0 15px;
  margin-top: 5px;
  color: black;
}

.results-mode-button{
  background: none;
  cursor: pointer;
  transition: 0.3s all;
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  font-weight: 600;
  padding: 0 15px;
  margin-top: 5px;
  color: black;
}

.mode-buttons{
  text-align: center;
}