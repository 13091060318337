.modal-x {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 28px;
  color: #b1c2d2;
  cursor: pointer;
}

.modal-container {
  text-align: center;
  width: 80%;
}

.green {
  border: 1px solid #28d478;
}

.red {
  border: 1px solid red;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
