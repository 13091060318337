@keyframes slideIn {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.stats-modal-container {
  width: 100%;
}

.stats-top-container {
  width: 33%;
}

.stats-top-container h2 {
  font-size: 16px;
  font-family: "Oswald", sans-serif;
  text-align: center;
}

.stats-grid-container {
  animation: slideIn 1000ms;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  min-height: 300px;
}

.stats-item {
  flex: 1;
}

.stat-data {
  font-size: 36px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-variant-numeric: proportional-nums;
  font-family: "Oswald", sans-serif;
}

.stats-main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.stat-label {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Cairo", sans-serif;
}

.guess-main-container {
  display: flex;
  flex-direction: column;
  width: 65%;
}

.guess-main-container h2 {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  text-align: center;
}
.guess-container {
  font-family: "Cairo", sans-serif;
  display: flex;
  flex-direction: row;
  max-height: 400px;
  min-height: 300px;
  font-weight: bold;
}

.bar-container {
  width: 18%;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  flex-direction: column-reverse;
  margin: 0 4px;
  animation: slideIn 1000ms;
}

.graph-bar {
  width: 100%;
  height: 100%;
  border: 1px solid black;
}

.graph-bar-inner {
  text-align: center;
}
