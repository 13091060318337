.about p{
    font-family: 'Cairo', sans-serif;
}

.about a{
    color: black;
}

@media screen and (max-width: 768px){
    .about p{
        font-size: 14px;
    } 
}