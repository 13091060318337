.disabled {
  opacity: 0.3;
  transition: 0.3s color;
}

.main-toggle-button {
  border: none;
  padding: 5px 10px;
  font-family: "Cairo", sans-serif;
  font-weight: 800;
  cursor: pointer;
  color: white;
  background-color: black;
  text-transform: uppercase;
  font-size: 11px;
}

.buttons-container {
  max-width: 800px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  margin-top: 10px;
}

.buttons-container-confirm {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.confirm-h2 {
  font-family: "Oswald", sans-serif;
  color: black;
}

.confirm-p {
  font-family: "Cairo", sans-serif;
  color: black;
}

.yes-button {
  background-color: #28d478;
  color: white;
  border: none;
  font-size: 12px;
  padding: 5px 10px;
  font-family: "Cairo", sans-serif;
  font-weight: 800;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
}

.no-button {
  background-color: red;
  color: white;
  border: none;
  font-size: 12px;
  padding: 5px 10px;
  font-family: "Cairo", sans-serif;
  font-weight: 800;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
}

.streak-item,
.streak-item-desktop {
  /* flex: 1; */
  border-radius: 1em;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 30%);
  margin: 0 5px;
  background-color: white;
  width: 100px;
  margin: 0 20px;
}

.streak-data {
  font-size: 36px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-variant-numeric: proportional-nums;
  font-family: "Oswald", sans-serif;
}

.streak-main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.streak-label {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Cairo", sans-serif;
}

@media screen and (min-width: 768px) {
  .confirm-h2 {
    font-size: 20px;
  }
  .confirm-p {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .confirm-h2 {
    font-size: 16px;
  }
  .confirm-p {
    font-size: 13px;
  }
}

@media screen and (min-width: 768px) {
  .streak-main-container {
    justify-content: center;
  }
}

@media screen and (min-width: 451px) and (max-width: 768px) {
  .buttons-container {
    max-width: 580px;
    justify-content: space-around;
  }
}

@media screen and (max-width: 450px) {
  .streak-item-desktop {
    display: none;
  }

  .buttons-container {
    max-width: 420px;
    justify-content: space-around;
  }
}
