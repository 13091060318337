.modal-reveal-link{
    font-family: 'Cairo', sans-serif;
    cursor: pointer;
    color: black;
    text-decoration: underline;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 20px;
}

@media screen and (min-width: 768px){
    .silhouette-image{
        height: 260px;
    }
}

@media screen and (max-width: 768px){
    .silhouette-image{
        height: 150px;
    }
}

.silhouette-image{
    max-width: 350px;
    width: 100%;
    filter: brightness(0);
    margin: auto;
}

.silhouette-container{
    font-family: 'Oswald', sans-serif;
    color: black;
}

.silhouette-container span{
    text-transform: uppercase;
    font-weight: 900;
}

.silhouette-container h2{
    font-weight: 400;
}

.reveal{
    filter: brightness(1);
}

.countdown p{
    font-size: 24px;
    font-family: 'Cairo', sans-serif;
    margin-top: 0;
}

.new-mystery{
    font-family: 'Cairo', sans-serif;
    margin-bottom: 0;
}

.result-player-name{
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    margin: 0;
}

.result-h3{
    font-family: 'Cairo', sans-serif;
    margin: 5px 0;
}

.share-button{
    margin: 10px 0;
    border: none;
    padding: 10px 20px;
    font-family: 'Cairo', sans-serif;
    font-weight: 800;
    cursor: pointer;
    color: white;
    text-transform: uppercase;
}

.game-container{
    animation: fadeInAnimation 0.5s ease-in;
}


@keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }