.table-container2 {
  margin-left: auto;
  margin-right: auto;
  font-family: "Cairo", sans-serif;
}

.table-head2 .table-row2 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  display: grid;
  font-size: 0.8em;
  text-transform: uppercase;
  color: black;
  font-weight: 900;
  font-family: "Cairo", sans-serif;
}

.table-body2 .table-row2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-top: 1px solid black;
  font-family: "Cairo", sans-serif;
  /* animation: fadeInAnimation 1s ease-in; */
}

.table-cell2 {
  text-align: center;
  margin: 0px 1px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-button {
  border: none;
  padding: 5px 10px;
  font-family: "Cairo", sans-serif;
  font-weight: 800;
  cursor: pointer;
  color: white;
  background-color: #b1c2d2;
  text-transform: uppercase;
  font-size: 10px;
}

.new-game-button {
  border: none;
  padding: 5px 10px;
  font-family: "Cairo", sans-serif;
  font-weight: 800;
  cursor: pointer;
  color: white;
  background-color: black;
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 15px;
}

.unlimited-info{
    animation: fadeInAnimation 0.3s ease-in;
}
