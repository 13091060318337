footer {
  padding: 0 10px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  font-family: "Cairo", sans-serif;
  border-top: 1px solid black;
}
footer a {
  color: black;
  width: 100%;
}

@media screen and (max-width: 768px) {
  footer {
    justify-content: center;
    flex-direction: column;
  }
  footer p:first-child {
    margin-top: 0;
  }
  footer p:last-child {
    margin: 0;
  }
}

.main-container {
  display: flex;
  align-content: center;
  flex-direction: column;
}
